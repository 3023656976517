$primary: #c84d99;

@import "~bootstrap/scss/bootstrap";

.spacing {
  padding-top: 30px;
  padding-bottom: 30px;
}

.button-spacing {
  margin-top: 15px;
}

.container {
  max-width: 400px;
  ;
}

.logo {
  text-align: center;
  padding: 2rem;
}